import React from 'react';
import { InView } from 'react-intersection-observer'
import {animated} from 'react-spring'
import { Spring } from 'react-spring/renderprops'
// import Nav from "../pages/Branchen";

class HookedComponent extends React.Component {
    // state = {
    //     showH1: false
    // };
  constructor(props) {
	super(props);
	  this.state = {
		showH1: false
	  };
	  console.log(this.props.items);
  	}
    onInViewChange = inview => {
        if (!this.state.showH1 && inview) this.setState({ showH1: true })
    };
    render() {
        return (
            <InView tag="div" className={"row p2-3 pb-3 text-center"} onChange={this.onInViewChange}>
			  {this.props.items.map((item, key) =>
				<Spring to={{ number: this.state.showH1 ? item.max : 0 }} delay={200} config={{duration: 800}}>
				  {props => (
					<div className={"col-6 col-lg-3 pb-4"}>
					  <animated.h1>
						{props.number.toFixed()}
					  </animated.h1>
					  {item.title}
					</div>
				  )}
				</Spring>
			  )}
            </InView>
        )
    }
}

export default HookedComponent