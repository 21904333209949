import React from "react";
import Container from 'react-bootstrap/Container';
import HookedComponent from '../components/HookedComponent.js';

export default class Innovationsforschung extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  error: null,
	  isLoaded: false,
	  data: {
		marker: '',
		title: '',
		items: [],
	  },
	  marker: '',
	  title: '',
	  subhead: '',
	  text: '',
	  items:[]
	};
	console.log(props);
	// console.log(this.state);
  }
// componentDidMount() {
//   // console.log(this.state.jsonfile);
//   // fetch(this.props.jsonfile)
// 	// .then(res => res.json())
// 	// .then(
// 	//   (result) => {
// 	// 	// console.log(result);
// 	// 	this.setState({
// 	// 	  isLoaded: true,
// 	// 	  data: result,
// 	// 	  marker: result.marker,
// 	// 	  title: result.title,
// 	// 	  text: result.text,
// 	// 	  subhead: result.subhead,
// 	// 	  items: result.items,
// 	// 	});
// 	// 	// console.log(this.state.data);
// 	//   },
// 	//   (error) => {
// 	// 	this.setState({
// 	// 	  isLoaded: true,
// 	// 	  error
// 	// 	});
// 	//   }
// 	// )
// }
render() {
  return (
    <article className={'flex fullpage text-left ' + this.props.bgcolor}>
        <Container>
            <div className={"row"}>
                <div className={"col-lg-12 pt-5"}><span className={"ltsp  text-lowercase"}>{this.props.data.marker}</span>
                    <h1>{this.props.data.title}</h1></div>
                <p className={"col-12 pt-4 pb-3 text-columns-2 hyphenate text-break"}>{this.props.data.text}</p>
			  <p className={"col-12 pt-4 pb-3 hyphenate text-break"}><b>{this.props.data.subhead}</b></p>
            </div>
            <HookedComponent items={this.props.data.items}/>
        </Container>
    </article>
  );
}
}
