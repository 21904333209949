import React from "react";
import Logoslider from '../components/Logoslider.js';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import ContentEditable from "react-contenteditable";

export default class Branchen extends React.Component {
  // constructor(props) {
	// super(props);
  // }
  handleChange = evt => {
	// console.log(evt.target);
	// console.log(evt.target.value);
  };
  render() {
	return (
	  <div>
	  <article className={'flex fullpage text-left ' + this.props.bgcolor}>
		<Container>
		  <Row>
		  <div className={"col-lg-12 pt-5"}>
			<div className={"row"}>
			  <div className={"col-lg-10  offset-lg-2"}>
				<div>
				  <span className={"ltsp text-lowercase"}>{this.props.data.marker}</span>
				  <h1>{this.props.data.title}</h1></div>
			  </div>
			</div>
			<div className={"row"}>
			  <div className={"col-12 pt-4 pb-1"}>
				<Tab.Container id="left-tabs-example" defaultActiveKey={this.props.marker + "tab0"}>
				  <Row>
					<Col md={4} lg={3} className={"flex-column d-none d-md-block content-left-subnav"}>
					  <Nav variant="pills" className={"flex-column"}>
						{this.props.data.items.map((item, key) =>
						  <Nav.Item>
							<Nav.Link eventKey={this.props.marker + "tab" + key}><i className={"las "+item.icon}></i> {item.nav}</Nav.Link>
						  </Nav.Item>
						)}

					  </Nav>
					</Col>
					<Col md={8} lg={8} className={"flex-column d-none d-md-block offset-lg-1 pb-1 hyphenate text-break"}>
					  <Tab.Content>
						{this.props.data.items.map((item, key) =>
						  <Tab.Pane eventKey={this.props.marker + "tab" + key}>
							<h2 className={"h4 col-lg-8"}>{item.title}</h2>
							<ContentEditable
							className="pb-1 hyphenate text-break col-lg-9"
							tagName="p"
							html={item.text} // innerHTML of the editable div
							disabled={true} // use true to disable edition
							onChange={this.handleChange} // handle innerHTML change
							/>
							<Logoslider marker={this.props.marker} logos={item.logos} />
						  </Tab.Pane>
						)}

					  </Tab.Content>
					</Col>
				  </Row>
				</Tab.Container>
				<Accordion defaultActiveKey={this.props.marker + "acc0"} className={"d-block d-md-none"}>
				  {this.props.data.items.map((item, key) =>
					<Card>
					  <Card.Header>
						<Accordion.Toggle as={Button} variant="link" eventKey={this.props.marker + "acc" + key}>
						<i className={"las "+item.icon}></i> {item.nav}
						</Accordion.Toggle>
					  </Card.Header>
					  <Accordion.Collapse eventKey={this.props.marker + "acc" + key}>
						<Card.Body>
						  <h2 className={"h4"}>{item.title}</h2>
						  <ContentEditable
						  className="pb-1 hyphenate text-break col-lg-9"
						  tagName="p"
						  html={item.text} // innerHTML of the editable div
						  disabled={true} // use true to disable edition
						  onChange={this.handleChange} // handle innerHTML change
						  />
						  <Logoslider marker={this.props.marker} logos={item.logos} />
						</Card.Body>
					  </Accordion.Collapse>
					</Card>
				  )}
				</Accordion>
				</div>
			</div>
		  </div>
		</Row>
		</Container>
	  </article>
	</div>
	);
  }
}

