import React from "react";
import styled from "styled-components";
import {Switch, Route, withRouter} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import Home from "../pages/Home";
import Privacy from "../pages/Privacy";
import Imprint from "../pages/Imprint";
import Navbar from "../components/Navbar";
import ScrollToTop from "./ScrollToTop"
import ScrollUp from "./ScrollUp";
// import { Element} from 'react-scroll'
function AppContainer({location}) {
  return (
	<Wrapper>
		{/*<TransitionGroup className="transition-group">*/}
		  {/*<CSSTransition key={location.key} timeout={{enter: 600, exit: 600}}>*/}
			<ScrollToTop>
		  <section className="route-section">
			<header><Navbar currloc={location} /></header>
			<Switch location={location}>
			  <Route exact path="/" component={Home}/>
			  <Route exact path="/datenschutz" component={Privacy}/>
			  <Route exact path="/impressum" component={Imprint}/>
			</Switch>
		  </section>
			</ScrollToTop>
		{/*</CSSTransition>*/}
	  {/*</TransitionGroup>*/}
	<ScrollUp/>
	</Wrapper>
  );
}

const Wrapper = styled.div`
// .slide-enter {
//
// }
// .slide-enter.slide-enter-active {
// -webkit-animation: moveToLeft 600ms ease both;
// animation: moveInToLeft 600ms ease both;
// }
// .slide-exit {
//
// }
// .slide-exit.slide-exit-active {
// -webkit-animation: moveInToLeft 600ms ease both;
// animation: moveToLeft 600ms ease both;
// }

.fade-enter {
opacity: 1;
}
.fade-enter.fade-enter-active {
opacity: 1;
transition: opacity 600ms ease-in;
}
.fade-exit {
opacity: 1;
}
.fade-exit.fade-exit-active {
opacity: 1;
transition: opacity 600ms ease-in;
}

// @-webkit-keyframes moveToLeft {
// from { }
// to { -webkit-transform: translateX(-100%); }
// }
// @keyframes moveToLeft {
// from { }
// to { -webkit-transform: translateX(-100%); transform: translateX(-100%); }
// }
// @-webkit-keyframes moveInToLeft {
// from { -webkit-transform: translateX(100%); }
// to { -webkit-transform: translateX(0%); }
// }
// @keyframes moveInToLeft {
// from { -webkit-transform: translateX(100%); }
// to { -webkit-transform: translateX(0%); }
// }

div.transition-group {
position: relative;
}
section.route-section {
position: absolute;
width: 100%;
top: 0;
left: 0;
}
`;

export default withRouter(AppContainer);