import React from "react";
// import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import logo from "../assets/standards/emares_logo_ohne_claim.svg";
// import {Events, scroller, scrollSpy} from "react-scroll/modules";
import * as Scroll from 'react-scroll';
import {Link as RRDLink }  from "react-router-dom";
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


export default class Home extends React.Component {
  constructor(props) {
      super(props);
  }

  componentDidMount() {
	console.log('this.props');
	console.log(this.props);
	Events.scrollEvent.register('begin', function(to, element) {
	  //console.log("begin", arguments);
	});

	Events.scrollEvent.register('end', function(to, element) {
	  //console.log("end", arguments);
	});

	scrollSpy.update();
  }
  componentWillUnmount() {
	Events.scrollEvent.remove('begin');
	Events.scrollEvent.remove('end');
  }
  handleSetActive(to) {
    // console.log(this);
	// console.log('----------to-----------');
	// console.log(to);
  }

  render() {
	const isHome = (this.props.currloc.pathname!=='/impressum' && this.props.currloc.pathname!=='/datenschutz');
	let navbar;
	// console.log(isHome);
	// console.log(this.props.currloc.path);
	if (isHome) {
	  navbar = <Nav className="d-none d-lg-flex mr-auto text-lowercase">
		<NavItem eventkey={2} href="#profil">
		  <Nav.Link as={Link} spy={"true"} activeClass={"active"} smooth={"true"} offset={-59} duration={500} onSetActive={this.handleSetActive} to="profil" >Mitdenker</Nav.Link>
		</NavItem>
		<NavItem eventkey={3} href="#referenzen">
		  <Nav.Link as={Link} spy={true} activeClass={"active"} smooth={true} offset={-59} duration={500}  onSetActive={this.handleSetActive} to="referenzen" >Expertise</Nav.Link>
		</NavItem>
		<NavItem eventkey={4} href="#methoden">
		  <Nav.Link as={Link}  spy={true} activeClass={"active"}  smooth={true} offset={-59} duration={500}  onSetActive={this.handleSetActive} to="methoden" >Methoden</Nav.Link>
		</NavItem>
		<NavItem eventkey={5} href="#partner">
		  <Nav.Link as={Link}  spy={true} activeClass={"active"} smooth={true} offset={-59} duration={500}  onSetActive={this.handleSetActive}  to="partner" >Partner</Nav.Link>
		</NavItem>
		<NavItem eventkey={6} href="#innovationsforschung">
		  <Nav.Link as={Link} spy={"true"} activeClass={"active"} smooth={true} offset={-59} duration={500} onSetActive={this.handleSetActive} to="innovationsforschung" >Über  uns</Nav.Link>
		</NavItem>
		<NavItem eventkey={7} href="#kontakt">
		  <Nav.Link as={Link}  spy={true} activeClass={"active"} smooth={true} offset={-59} duration={500} onSetActive={this.handleSetActive}  to="kontakt" >Kontakt</Nav.Link>
		</NavItem>
	  	</Nav>;
	} else {
	  navbar = <Nav className="d-none d-lg-flex mr-auto text-lowercase"> </Nav>;
	}
	return (
	  <Navbar bg="light"  fixed="top">
		<Container>
		  <NavItem eventkey={9} >
			<Nav.Link as={RRDLink} to="/" href="/" className={"logolink"} >
			  <img
				src={logo}
				width="auto"
				height="30"
				className="svglogo d-inline-block align-top"
				alt="e-mares Logo"
			  />
			</Nav.Link>
		  </NavItem>
		  <Navbar.Toggle aria-controls="basic-navbar-nav" />
		  <Navbar.Collapse id="basic-navbar-nav">
			{navbar}
			<Nav>
			  <Nav.Link href="mailto:info@e-mares.de"><i className={"las la-at d-flex d-md-none"}></i><span className={"d-none d-md-flex"}> info@e-mares.de</span></Nav.Link>
			  <Nav.Link href="tel:+4951098069151"><i className={"la la-phone d-flex d-md-none"}></i><span className={"d-none d-md-flex"}> +49 (0)5109 / 80 69 15-1</span></Nav.Link>
			  <NavItem eventkey={8} className={"d-flex d-md-none"}>
				<Nav.Link as={Link}  spy={true} activeClass={"active"} smooth={true} offset={-59} duration={500} onSetActive={this.handleSetActive}  to="kontakt" ><i className={"la la-address-card  d-flex d-md-none"}></i></Nav.Link>
			  </NavItem>
			</Nav>
		  </Navbar.Collapse>
		</Container>
	  </Navbar>
	);
  }
}
