import React from "react";
import Image from 'react-bootstrap/Image'
export default class Branchen extends React.Component {
  render() {
	return (
    <article className={'flex fullpage text-left ' + this.props.bgcolor}>
	  <div className={"container"}>
		<div className={"row"}>
		  <div className={"col-lg-12 pt-5"}>
			<div className={"row"}>
			  <div className={"col-lg-6"}>
				<span className={"ltsp text-lowercase"}>{this.props.data.marker}</span>
				<h1>{this.props.data.title}</h1>
			  </div>
			</div>
			<div className={"row"}>
			  <div className={"col-md-6 col-12 col-lg-6 offset-xl-1 pt-4 pb-1 hyphenate text-break"}>
				<Image src="../assets/standards/germany.svg" width={150} alt={"e-mares Forschungsnetzwerk Deutschland"} className={"d-inline d-md-none float-left"} fluid />
				<p className={"d-inline d-md-block"}>{this.props.data.text}</p>
				<p className="pt-3 border-top"><b>{this.props.data.subhead}</b></p>
				<div className={"row"}>
				<div className={"col-4 pt-4"}>
				  <Image src="../assets/standards/customer/td.svg" width={400} alt={"e-mares Forschungsnetzwerk Deutschland"} fluid />
				</div>
				<div className={"col-4 pt-4"}>
				  <Image src="../assets/standards/customer/ftt.png" width={400} alt={"e-mares Forschungsnetzwerk Deutschland"} fluid />
				</div>
				<div className={"col-4 pt-4"}>
				  <Image src="../assets/standards/customer/fttm.png" width={400} alt={"e-mares Forschungsnetzwerk Deutschland"} fluid />
				</div>
			  </div>
			  </div>
			  <div className={"d-none d-md-block col-sm-6 col-lg-4 offset-lg-1 pt-4 text-center"}>
				<Image src="../assets/standards/germany.svg" width={400} alt={"e-mares Forschungsnetzwerk Deutschland"} fluid />
			  </div>
			</div>
		  </div>
		</div>
	  </div>
    </article>
	);
  }
}
