import React from "react";
import Container from 'react-bootstrap/Container';
import {Element, Events, scrollSpy} from "react-scroll/modules";
import Footer from "../components/Footer.js";
import nl2br from 'react-newline-to-break';
export default class Privacy extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  error: null,
	  isLoaded: false,
	  data: {
		footer:{
		  title: '',
		  text: '',
		},
		default:{
		  email: "",
		  teltext: "",
		  tellink: "",
		  address: "",
		  zip: "",
		  city: "",
		  organization_name: "",
		  footer_description: "",
		  bvm_short: "",
		  bvm_long: "",
		  initiative: "" ,
		  datenschutzbeauftragter:""
		},
		title: '',
		items: [],
	  }
	};
  }
  componentDidMount() {
	fetch(`../res/data.json`)
	  .then(res => res.json())
	  .then(
		(result) => {
		  // console.log(result);
		  this.setState({
			isLoaded: true,
			data: result
		  });
		  // console.log(this.state.data);
		},
		(error) => {
		  this.setState({
			isLoaded: true,
			error
		  });
		}
	  );
  }
  render() {
	return (
	  <div>
    <article className={'flex fullpage text-left'}>
        <Container>
            <div className={"row"}>
                <div className={"col-lg-12 pt-5"}><span className={"ltsp  text-lowercase"}>Privacy</span>
                    <h1>Datenschutz&shy;erklärung</h1></div>
                <div className={"col-12 pt-4 pb-3 hyphenate text-break"}>
				  Sehr geehrte Besucherinnen und Besucher, wir freuen uns über Ihren Besuch auf unseren Webseiten. Wir möchten, dass Sie sich hierbei sicher und wohl fühlen. Der Schutz Ihrer Privatsphäre hat für uns einen hohen Stellenwert. Die folgenden Datenschutzbestimmungen sind dafür gedacht, Sie über unsere Handhabung der Erhebung, Verwendung und Weitergabe von persönlichen Daten zu informieren.
				  <br/><br/>
				  <h2 className={"h5"}>Verantwortliche Stelle</h2>
				  e-mares Innovationsforschung<br/>
				  Ulf Endewardt<br/>
				  {this.state.data.default.address}<br/>
				  {this.state.data.default.zip} {this.state.data.default.city}<br/>
				  Tel: <a href={"tel:" + this.state.data.default.tellink} tabIndex="-1" aria-selected="false">{this.state.data.default.teltext}</a><br/>
				  E-Mail: <a href={"mailto:" + this.state.data.default.email} tabIndex="-1" aria-selected="false">{this.state.data.default.email}</a><br/>
				  Internet: <a href="https://www.e-mares.de" tabIndex="-1" aria-selected="false">www.e-mares.de</a><br/>
				  <br/>
				  <h2 className={"h5"}>Datenschutzbeauftragter (TÜV) gemäß DSGVO und BDSG-neu</h2>
				  Verantwortlich für die Einhaltung und Kontrolle unserer Datenschutzpflichten ist unser Datenschutzbeauftragter. Dieser steht Ihnen auch gern für weitere Auskünfte rund um das Thema Datenschutz zur Verfügung.<br/>
				  <br/>
				  {nl2br(this.state.data.default.datenschutz)}<br/>
				  <br/>
				  <h2 className={"h5"}>Nutzungsdaten</h2>
				  Um die Qualität und Funktionsfähigkeit unserer Webseiten zu verbessern und für den Fall einer Strafverfolgung, speichern wir zu statistischen Zwecken Daten über den einzelnen Zugriff auf unsere Seiten. Dieser Datensatz besteht aus<br/> <br/>
				  <ul>
					<li>der Seite, von der aus die Datei angefordert wurde,</li>
					<li>dem Namen der Datei,</li>
					<li>dem Datum und der Uhrzeit der Abfrage,</li>
					<li>der übertragenen Datenmenge,</li>
					<li>dem Zugriffsstatus (Datei übertragen, Datei nicht gefunden),</li>
					<li>Beschreibung des Typs des verwendeten Webbrowsers,</li>
					<li>der IP-Adresse des anfragenden Rechners </li>
				  </ul>
				  Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO (berechtigten Interessen des Verantwortlichen). Die o.g. Gründe stellen auch das berechtigte Interesse für die Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO dar. <br/>
				  <br/>
				  <h2 className={"h5"}>Umgang mit personenbezogenen Daten</h2>
				  Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.<br/>
				  <br/>
				  Die Nutzung unserer Website ist grundsätzlich ohne eine Angabe von personenbezogenen Daten möglich. Wir verwenden auch keine Kontaktformulare. Sollten Sie Kontakt zu uns aufnehmen wollen, so senden Sie uns bitte eine Mail oder rufen Sie uns gerne an.
				  <br/>
				  Ihre personenbezogenen Daten werden wir nur zu dem von Ihnen gewünschtem Zweck, beispielsweise zur Kontaktaufnahme, Angebotserstellung oder Beantwortung Ihrer Fragen nutzen und speichern. Außerdem speichern und verarbeiten wir Daten über den Auftrags- und Zahlungsverlauf.
				  <br/>
				  Wir geben Ihre Daten grundsätzlich nicht weiter. Es sei denn, dies ist im geschäftlichen Kontext sinnvoll und Sie haben der Datenweitergabe vorab zugestimmt.
				  <br/>
				  Sollten Sie sich als Bewerber an uns wenden, so verarbeiten wir die Daten, die Sie im Bewerbungsprozess angeben. Dazu gehören Ihr Name, Adresse, Telefon- und Mobilnummern, E-Mail Adressen und die personenbezogenen Daten, die sich aus Ihren Bewerbungsunterlagen ergeben.
				  <br/><br/>
				  <h2 className={"h5"}>Cookies</h2>
				  Diese Website nutzt keine „Cookies“, d.h. es werden keine Textdateien auf Ihrem Endgerät gespeichert.
				  <br/><br/>
				  <h2 className={"h5"}>Ihr Rechte als Nutzer</h2>
				  <ol type="a">
					<li>Recht auf Bestätigung<br/>
					   Jede betroffene Person hat das Recht, Auskunft zu verlangen, ob über sie personenbezogene Daten verarbeitet werden.</li>
					<li>Recht auf Auskunft (Art. 15 DSGVO)<br/>
					  Jede betroffene Person hat das Recht, unentgeltliche Auskunft über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten.</li>
					<li>Recht auf Berichtigung (Art. 16 DSGVO)<br/>
					  Die betroffene Person hat das Recht, von dem Verantwortlichen unverzüglich die Berichtigung sie betreffender unrichtiger personenbezogener Daten zu verlangen.</li>
				 <li>Recht auf Löschung (Recht auf Vergessen werden) (Art. 17 DSGVO)
				   Jede betroffene Person hat das Recht, zu verlangen, dass die sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der gesetzlich genannten Gründe zutrifft und soweit die Verarbeitung nicht erforderlich ist.</li>
					<li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)
					  Jede betroffene Person hat das Recht, die Einschränkung der Verarbeitung zu verlangen, sofern einer der gesetzlich genannten Gründe zutrifft.</li>
					<li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)
					  Jede betroffene Person hat das Recht, die sie betreffenden personenbezogenen Daten, welche durch sie einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen ohne Behinderung zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.
					  Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</li>
					<li>Recht auf Widerruf einer datenschutzrechtlichen Einwilligung (Art. 13 DSGVO)
					  Jede betroffene Person hat das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen, wenn die Verarbeitung auf Art.6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a beruht, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.</li>
					<li>Recht auf Widerspruch (Art. 21 DSGVO)
					  Jede betroffene Person hat das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.</li>
					<li>Automatisierte Entscheidungen im Einzelfall einschließlich Profiling (Art. 22 DSGVO)
					  Jede betroffene Person hat das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung - einschließlich Profiling - beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung
					  (1) nicht für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist, oder
					  (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der betroffenen Person enthalten oder
					  (3) mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.
					  In den in (1) und (3) genannten Fällen werden angemessene Maßnahmen getroffen, um die Rechte und Freiheiten sowie die berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.</li>
				  </ol>
				  <br/>
				  <h2 className={"h5"}>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h2>
				  Im Falle von datenschutzrechtlichen Verstößen hat die betroffene Person ein Beschwerderecht bei der zuständigen Aufsichtsbehörde. Dies ist der oder die Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten finden Sie hier:
				  Internet: www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
				  <br/>
				  Dauer, für die die personenbezogenen Daten gespeichert werden
				  <br/>
				  Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzlich vorgeschriebene Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.
				  <br/><br/>
				  <i className={"text-muted"}>Datenschutzerklärung erstellt mit dem interaktiven Muster für die Datenschutzerklärung von 123recht.net - Rechtsberatung online.</i>
				</div>
            </div>
        </Container>
    </article>
	<Element id="kontakt" name="kontakt" >
	 <Footer default={this.state.data.default} data={this.state.data.footer} />
	</Element>
  </div>
);
}
}

