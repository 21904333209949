import React from "react";
import Container from 'react-bootstrap/Container';
import logo_claim from "../assets/standards/emares_logo_mit_claim.svg";
import Image from 'react-bootstrap/Image'
import Innovationsforschung from './Innovationsforschung.js';
import Branchen from './Branchen.js';
// import CMSForm from './CMSForm.js';
import Partner from './Partner.js';
import Profil from './Profil.js';
// import { Element , Events, scrollSpy, scroller } from 'react-scroll'
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import Footer from "../components/Footer.js";
import Nav from "../components/Navbar";

export default class Home extends React.Component {
    constructor(props) {
        super(props);
	  this.state = {
		error: null,
		isLoaded: false,
		data: {
		  intro: {
		    marker: '',
			title: '',
			items: []
		  },
		  profil:{
			data: [],
			title: '',
			text: '',
			subhead: '',
			bio: ''
		  },
		  referenzen:{
			marker: '',
			title: '',
			items: [],
		  },
		  methoden:{
			marker: '',
			title: '',
			items: [],
		  },
		  partner:{
			marker: '',
			title: '',
			text: '',
			subhead: '',
		  },
		  footer:{
			title: '',
			text: '',
		  },
		  default:{
			email: "",
			teltext: "",
			tellink: "",
			address: "",
			zip: "",
			city: "",
			organization_name: "",
			footer_description: "",
			bvm_short: "",
			bvm_long: "",
			initiative: ""
		  },
		  title: '',
		  items: [],
		}
	  };
	  // this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
	  fetch(`../res/data.json`)
		.then(res => res.json())
		.then(
		  (result) => {
			// console.log(result);
			this.setState({
			  isLoaded: true,
			  data: result
			});
			// console.log(this.state.data);
		  },
		  (error) => {
			this.setState({
			  isLoaded: true,
			  error
			});
		  }
		);
        Events.scrollEvent.register('begin', function(to, element) {
            //console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function(to, element) {
            //console.log("end", arguments);
        });
        scrollSpy.update();
    }
    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    // handleClick(e) {
    //     e.preventDefault();
    //     // console.log(e);
    //     scroller.scrollTo('innovationsforschung', {
    //         duration: 800,
    //         delay: 0,
    //         smooth: 'easeInOutQuart',
    //         offset: 0
    //     })
    // }

    render() {
	  return (
            <div>
                <div className={'flex fullpage eheader  bg-inverse bg-blue text-white text-center'}>
                    <div className="h-100 d-flex justify-content-center flex-column  mb-3">
                        <Container className="h-100 d-flex align-items-stretch flex-column " >
                            <div className="p-2 text-center">
                                <img src={logo_claim} alt={'e-mares Innovationsforschung'} width={260}/>
                            </div>
                            <div className="mt-auto mb-auto p-2 "><h1><span>je </span>mehr <span>dinge sich gleichen, desto </span>wichtige<span>r werden </span>unterschiede</h1></div>
                            <div className="h-25">
                                <div className="row h-100 ">
                                    <div className="h-100  col-xs offset-6 text-left">
                                        <div className="h-100  d-flex align-items-stretch flex-column  mb-3">
										  <Link className="h-25 btm-link ltsp text-white a-href" spy={true} activeClass={"active"} smooth={true} offset={-59} duration={500} onSetActive={this.handleSetActive} to="profil" >entdecken</Link>
                                            <div className="h-75 topline  animated fadeIn slow"> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <div className={"engagement "}>
						  <Image className={"d-none d-sm-block img"} src={'/assets/standards/marktforscher_bvm_invers.svg'} width={200} alt={'Marktforscher BVM'} fluid />
						  <span className={"d-inline-block d-sm-none img"}>Marktforscher BVM</span>
                        </div>
                    </div>
                </div>
					<Element id="mitdenker" name="profil" >
					  <Profil data={this.state.data.profil} />
					</Element>
					<Element id="expertise" name="referenzen" >
					  <Branchen bgcolor={"bg-light-blue"}  marker={"referenzen"} data={this.state.data.referenzen} jsonfile={`../res/referenzen.json`} />
					</Element>
					<Element id="methoden" name="methoden" >
					  <Branchen bgcolor={""} marker={"methoden"} data={this.state.data.methoden} jsonfile={`../res/methoden.json`} />
					</Element>
					<Element id="partner" name="partner"  >
					  <Partner bgcolor={"bg-light-blue"} data={this.state.data.partner}/>
					</Element>
					<Element id="innovationsforschung" name="innovationsforschung" >
					  <Innovationsforschung bgcolor={""} data={this.state.data.intro} />
					</Element>
					<Element id="kontakt" name="kontakt" >
					  <Footer default={this.state.data.default} data={this.state.data.footer} />
					</Element>
			  {/*<Element id="form" name="form"  >*/}
				{/*<CMSForm jsonfile={`../res/partner.json`}/>*/}
			  {/*</Element>*/}
            </div>
        );
    }
}
