import React from "react";
import Container from 'react-bootstrap/Container';
import {Element} from "react-scroll/modules";
import Footer from "../components/Footer.js";
export default class Imprint extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  error: null,
	  isLoaded: false,
	  data: {
		footer:{
		  title: '',
		  text: '',
		},
		default:{
		  email: "",
		  teltext: "",
		  tellink: "",
		  address: "",
		  zip: "",
		  city: "",
		  organization_name: "",
		  footer_description: "",
		  bvm_short: "",
		  bvm_long: "",
		  initiative: "",
		  steuernr: ""
		},
		title: '',
		items: [],
	  }
	};
  }
  componentDidMount() {
	fetch(`../res/data.json`)
	  .then(res => res.json())
	  .then(
		(result) => {
		  // console.log(result);
		  this.setState({
			isLoaded: true,
			data: result
		  });
		  // console.log(this.state.data);
		},
		(error) => {
		  this.setState({
			isLoaded: true,
			error
		  });
		}
	  );
  }
  render() {
	return (
  <div>
    <article className={'flex fullpage text-left'}>
        <Container>
            <div className={"row"}>
                <div className={"col-lg-12 pt-5"}><span className={"ltsp  text-lowercase"}>Anbieterkennzeichnung</span>
                    <h1>Impressum</h1></div>
                <div className={"col-12 pt-4 pb-3 hyphenate text-break"}>
				  e-mares Innovationsforschung <br/>
				  Inhaber: Ulf Endewardt   <br/>
				  {this.state.data.default.address}, {this.state.data.default.zip} {this.state.data.default.city} <br/>
				  Tel: <a href={"tel:" + this.state.data.default.tellink} tabIndex="-1" aria-selected="false">{this.state.data.default.teltext}</a> <br/>
				  Rechtsform: Einzelunternehmen  <br/>
				  Umsatzsteueridentifikationsnummer: DE115469356  <br/>
				  Steuernummer: {this.state.data.default.steuernr}<br/>
				  Finanzamt Hannover-Land I
				  <br/><br/>
				  E-Mail: <a href={"mailto:" + this.state.data.default.email} tabIndex="-1" aria-selected="false">{this.state.data.default.email}</a><br/>
				  Internet: <a href="https://www.e-mares.de" tabIndex="-1" aria-selected="false">www.e-mares.de</a><br/>
				  <br/>
				  Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:  <br/>
				  Ulf Endewardt (Anschrift wie oben)   <br/>
				  <br/>
				  Datenschutz: <a href="https://www.e-mares.de/datenschutz" tabIndex="-1" aria-selected="false">www.e-mares.de/datenschutz</a><br/>
				  <br/>
				  Gestaltung und Umsetzung:  <br/>
				  <a href="https://izwd.de" tabIndex="-1" aria-selected="false">www.izwd.de</a>
				</div>
            </div>
        </Container>
    </article>
	<Element id="kontakt" name="kontakt" >
	  <Footer default={this.state.data.default} data={this.state.data.footer} />
	</Element>
  </div>
	);
  }
}

