import React from "react";
import Image from 'react-bootstrap/Image'
export default class Branchen extends React.Component {
  // constructor(props) {
	// super(props);
	// // this.state = {
	// //   error: null,
	// //   isLoaded: false,
	// //   data: {
	// // 	title: '',
	// // 	items: [],
	// //   },
	// //   title: '',
	// //   text: '',
	// //   subhead: '',
	// //   bio: ''
	// // };
  // }
  // componentDidMount() {
	// fetch(this.props.jsonfile)
	//   .then(res => res.json())
	//   .then(
	// 	(result) => {
	// 	  // console.log(result);
	// 	  this.setState({
	// 		isLoaded: true,
	// 		data: result,
	// 		title: result.title,
	// 		text: result.text,
	// 		subhead: result.subhead,
	// 		bio: result.bio
	// 	  });
	// 	},
	// 	(error) => {
	// 	  this.setState({
	// 		isLoaded: true,
	// 		error
	// 	  });
	// 	}
	//   )
  // }
  render() {
	return (
  <article className={'flex text-left ' + this.props.bgcolor}>
	<div className={"container"}>
	  <div className={"row"}>
		<div className={"col-lg-12 pt-5"}>
		  <div className={"row"}>
			<div className={"col-lg-6 d-none d-lg-flex align-content-end flex-wrap"}>
			  <picture>
				<source type="image/webp" srcSet="../assets/standards/ulf_endewart_01_sw.webp" alt={"Ulf Endewart"}/>
				<source type="image/png" srcSet="../assets/standards/ulf_endewart_01_sw.png" alt={"Ulf Endewart"}/>
				<Image src="../assets/standards/ulf_endewart_01_sw.png" width={428} alt={"Ulf Endewart"} fluid />
			  </picture>
			</div>
			<div className={"col-lg-6 pt-4 pb-5 hyphenate text-break"}>
			  <span className={"ltsp text-lowercase"}>{this.props.data.marker}</span>
			  <h1>{this.props.data.title}</h1>
			  <p>{this.props.data.text}</p>
			  <div className="media d-flex d-lg-none">
				<picture>
				  <source type="image/webp" srcSet="../assets/standards/ulf_endewart_02_sw.webp" alt={"Ulf Endewart"}/>
				  <source type="image/png" srcSet="../assets/standards/ulf_endewart_02_sw.png" alt={"Ulf Endewart"}/>
				  <Image src="../assets/standards/ulf_endewart_02_sw.png" width={150} alt={"Ulf Endewart"} className={"align-self-start border mr-2"} roundedCircle  />
				</picture>
				  <div className="media-body">
					<b>{this.props.data.subhead}</b>
					<p>{this.props.data.bio}</p>
				  </div>
			  </div>
			  <p className={"d-none d-lg-block"}>
				<b>{this.props.data.subhead}</b> <br/>{this.props.data.bio}<br/>
			  </p>
			  <Image src={'/assets/standards/marktforscher_bvm_sw.svg'} width={200} alt={'Marktforscher BVM'} fluid />
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </article>
	);
  }
}

