import React from "react";
import Slider from "react-slick";
import Image from 'react-bootstrap/Image'
export default class Logoslider extends React.Component {
  constructor(props) {
	// console.log(props);
	super(props);
	this.state = {
	  logos: props.logos,
	  marker: props.marker
	};

  }
   render() {
	const settings = {
	  dots: true,
	  infinite: true,
	  slidesToShow: (this.state.logos.length < 3 ? this.state.logos.length : 3) ,
	  slidesToScroll: (this.state.logos.length < 3 ? this.state.logos.length : 3) ,
	  speed: 500,
	  rows: 1,
	  // autoplay: true,
	  // autoplaySpeed: 3000,
	  // pauseOnHover: true,
	  swipeToSlide: true ,
	  responsive: [
		{
		  breakpoint: 1024,
		  settings: {
			slidesToShow: (this.state.logos.length < 3 ? this.state.logos.length : 3),
			slidesToScroll: (this.state.logos.length < 3 ? this.state.logos.length : 3),
			infinite: true,
			dots: true
		  }
		},
		{
		  breakpoint: 600,
		  settings: {
			slidesToShow: (this.state.logos.length < 2 ? this.state.logos.length : 2),
			slidesToScroll: (this.state.logos.length < 2 ? this.state.logos.length : 2)
		  }
		}
		// You can unslick at a given breakpoint now by adding:
		// settings: "unslick"
		// instead of a settings object
	  ]
	};
	const hasLogos = this.state.logos.length;
	return (
	  <div className={"col-12"}>
		{hasLogos ? (
		  <div className={"logo-slider mb-4"}>
			<p className={"pt-3 border-top"}><b>Kunden</b></p>
			<Slider {...settings}>
			  {this.state.logos.map((item, key) =>
				<div key={this.state.marker + "_logo_"+ key} >
				  <h4 className={"h5 mt-0 text-center"}>
					<Image className={"slider-logo-image"} src={item.url} width={247} alt={item.name} fluid />
				  </h4>
				</div>
			  )}
			</Slider>
		  </div>
		) : (
		  <div className={"logo-slider"}> </div>
		)}
	  </div>
	);
  }
}