import React from "react";
import ScrollUpButton from "react-scroll-up-button";

export default class ScrollUp extends React.Component {
  render() {
	return (
	  <div>
		<ScrollUpButton />
	  </div>
	);
  }
}