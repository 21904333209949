import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import {Link} from "react-router-dom";

export default class Footer extends React.Component {
  render() {
	return (
	  <footer>
		<Container className={"flex fullpage text-left text-white"}> <Row>
		  <div className={"col-lg-12 pt-5"}>
			<div className={"row"}>
			  <div className={"col-lg-10"}>
				<div>
				  <h1>{this.props.data.title}</h1>
				</div>
				<p className={"lead pb-5"}>{this.props.data.text}<br/> Tel: <a href={"tel:" + this.props.default.tellink} tabIndex="-1" aria-selected="false">{this.props.default.teltext}</a>
				</p>
			  </div>
			</div>
		  </div>
		  <div className="col-md-4  pb-5" itemScope itemType="http://schema.org/Organization">
			<span itemProp="name">e-mares Innovationsforschung</span> <br/>
			<div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
			  <span itemProp="streetAddress">{this.props.default.address}</span>, <span itemProp="postalCode">{this.props.default.zip}</span> <span itemProp="addressLocality">{this.props.default.city}, Deutschland</span>
			</div>
			Tel: <a itemProp="telephone" href={"tel:" + this.props.default.tellink} tabIndex="-1" aria-selected="false">{this.props.default.teltext}</a><br/> E-Mail: <a itemProp="email" href={"mailto:" + this.props.default.email} tabIndex="-1" aria-selected="false">{this.props.default.email}</a><br/>
		  </div>
		  <div className="col-md-4 col-xl-5">
			<p>{this.props.default.footer_description}</p>
		  </div>
		  <div className="col-12">
			<hr className="border border-white"/>
			<Nav> <Nav.Link as={Link} to="/datenschutz" className={"pl-0"}>Datenschutz</Nav.Link>
			  <Nav.Link as={Link} to="/impressum" className={"pl-0"}>Impressum</Nav.Link> </Nav>
			<hr className="border border-white"/>
			{this.props.default.initiative}
		  </div>
		</Row> </Container>
	  </footer>
	);
  }
}
