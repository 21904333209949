import React from "react";
import { render } from "react-dom";
import { BrowserRouter} from "react-router-dom";
import {Helmet} from "react-helmet";
import AppContainer from "./components/AppContainer";
import 'whatwg-fetch'
import './assets/css/custom.scss'; 
import './assets/css/animate.css';
import './assets/css/paralax.css';
import "./assets/css/line-awesome.scss";
import * as registerServiceWorker from './serviceWorker';


const App = () => (
    <BrowserRouter>
	  <Helmet>
		<title>e-mares – Marktforschung & Innovationsforschung Hannover - Ulf Endewardt</title>
		<meta name="description" content="e-mares Innovationsforschung – Institut für Markt-, Sozial- und Zukunftsforschung in Hannover mit bundesweitem Forschungsnetzwerk" />
	  </Helmet>
		  <main>
			<AppContainer />
		  </main>
    </BrowserRouter>
);
render(<App />, document.getElementById("root"));
registerServiceWorker.register(
  
);